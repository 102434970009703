import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import ContactAnimation from '../components/ContactAnimation'
import HaveQuestions from '../components/StillHaveQuestions'

const PopUpsubmissionResult = () => {
  return (
    <Layout className={''} breadcrumbs={false}>
      <Seo title="THANK YOU" noindex={true} />
      <div className="py-10">
        <h1 className="text-5xl uppercase text-center">Thank you</h1>
        <div className="w-10/12 mx-auto max-w-[600px]">
          <ContactAnimation />
        </div>
        <p className="w-10/12 mx-auto max-w-[800px] text-lg">
          Your message has been received. Please allow up to 48 hours for a response. For a quicker
          response, you can call our showroom during business hours Monday through Saturday.
        </p>
      </div>
      <hr className="w-10/12 mx-auto" />
      <HaveQuestions />
      <section className={''}>
        <Map />
      </section>
    </Layout>
  )
}

export default PopUpsubmissionResult
