import React from 'react'

const ContactAnimation = () => {
  return (
    <svg
      role={'img'}
      aria-label={'Mail Envelope Animation'}
      viewBox="0 0 800 800"
      className="contact-svg w-full"
    >
      <path
        className="inside-envelope -z-10"
        fill="#003C86"
        d="M660.8,421.2c0.6-6.9-2.5-13.6-8.1-17.7C614.6,375.4,467,267,432,241.3c-4.6-3.4-10.6-3.9-15.7-1.5
		C376.8,259,208.1,340.3,164.6,361.3c-6.3,3.1-10.6,9.3-11.2,16.3c-4.3,51-21.9,259.4-27.4,325c-0.9,11,7.2,20.6,18.1,21.5
		c81,7,386.5,33.4,467.6,40.4c5.3,0.5,10.5-1.2,14.6-4.6c4-3.4,6.6-8.3,7-13.6C638.9,680.6,656.5,471.8,660.8,421.2z"
      />
      <g className="at-symbol-card">
        <path
          fill="#E3F0FF"
          d="M523.8,85c0.9-3.7,0.3-7.7-1.7-11c-2-3.3-5.2-5.6-9-6.5c-30.2-7.2-100.7-24.1-130.8-31.3
			c-7.8-1.9-15.6,2.9-17.5,10.7c-6.9,28.6-22.6,93.1-29.5,121.8c-0.9,3.7-0.3,7.7,1.7,11c2,3.3,5.2,5.6,9,6.5
			c30.2,7.2,100.7,24.1,130.8,31.3c7.8,1.9,15.6-2.9,17.5-10.7C501.2,178.2,516.8,113.6,523.8,85z"
        />
        <path
          fill="#006DF3"
          d="M382.3,179.5c-2.1-0.5-4.1-1-6-1.4l-0.8-0.2c-0.9-0.2-1.8,0.3-2,1.2c0,0.1,0,0.2,0,0.4c0,0.8,0.5,1.4,1.3,1.6
		l1.7,0.4c1.7,0.4,3.4,0.8,5.2,1.2c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3c0.1-0.4,0-0.9-0.2-1.3
		C383.1,179.9,382.8,179.6,382.3,179.5z M357.3,145.1c0.3-1.2,0.6-2.4,0.9-3.6c0-0.1,0-0.3,0-0.4c0-0.8-0.5-1.4-1.3-1.6
		c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3c-0.3,1.4-0.7,2.7-1,4l-0.7,2.8c-0.1,0.4,0,0.9,0.2,1.2c0.2,0.4,0.6,0.6,1,0.7
		c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3L357.3,145.1z M364.9,175.3c-0.5-0.1-1.1-0.3-1.6-0.4c-1.6-0.4-3-1-4.3-1.9
		c-0.3-0.2-0.6-0.3-0.9-0.3c-0.5,0-1.1,0.3-1.4,0.7c-0.2,0.3-0.3,0.6-0.3,1c0,0.5,0.3,1,0.7,1.3c1.7,1.1,3.5,2,5.5,2.4
		c0.3,0.1,0.7,0.2,1.1,0.3l0.5,0.1c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3C366.3,176.4,365.8,175.5,364.9,175.3z M369.6,87.2
		c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3l-0.3,1.4c-0.4,1.8-0.9,3.6-1.3,5.5c-0.2,0.9,0.3,1.8,1.2,2c0.1,0,0.3,0,0.4,0
		c0.8,0,1.4-0.5,1.6-1.3c0.3-1.1,0.5-2.1,0.8-3.2c0.3-1.2,0.6-2.4,0.9-3.6c0-0.1,0-0.2,0-0.4C370.9,88.1,370.4,87.4,369.6,87.2z
		 M375.1,71.8c0-0.1,0-0.2,0-0.4c0-0.8-0.5-1.4-1.3-1.6c-0.9-0.2-1.8,0.3-2,1.2l-1.7,6.8c-0.1,0.4,0,0.9,0.2,1.2
		c0.2,0.4,0.6,0.6,1,0.7c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3c0.3-1.2,0.6-2.5,0.9-3.7C374.6,73.8,374.8,72.8,375.1,71.8z
		 M460,70.1c2.1,0.5,4.1,1,6.1,1.5l0.7,0.2c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3c0-0.1,0-0.2,0-0.4c0-0.8-0.5-1.4-1.3-1.6
		c-1-0.2-2-0.5-3-0.7c-1.3-0.3-2.5-0.6-3.8-0.9c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3C458.5,69,459.1,69.9,460,70.1z
		 M365.4,104.6c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3c-0.3,1.3-0.6,2.6-1,3.9l-0.7,2.9c-0.2,0.9,0.3,1.8,1.2,2
		c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3c0.3-1.2,0.6-2.3,0.9-3.5l0.8-3.3c0-0.1,0-0.2,0-0.4C366.7,105.5,366.2,104.8,365.4,104.6
		z M352.7,156.9c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1-0.1,0.2c-0.3,1.2-0.5,2.5-0.5,3.8
		c0,1.1,0.1,2.3,0.4,3.4c0.2,0.8,0.8,1.3,1.6,1.3c0.1,0,0.2,0,0.3,0c0.9-0.2,1.5-1.1,1.3-2c-0.2-0.9-0.3-1.8-0.3-2.7
		c0-1,0.1-2,0.4-3l0-0.1c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.4C354,157.8,353.5,157.1,352.7,156.9z M358.7,132.1
		c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3c0.5-2.2,1.1-4.5,1.7-6.8c0-0.1,0-0.2,0-0.4c0-0.8-0.5-1.4-1.3-1.6c-0.1,0-0.3,0-0.4,0
		c-0.8,0-1.4,0.5-1.6,1.3l-0.5,2.1c-0.4,1.6-0.8,3.2-1.1,4.7c-0.1,0.4,0,0.9,0.2,1.3C357.9,131.7,358.3,132,358.7,132.1z
		 M494.9,78.4c0.2,0,0.3,0.1,0.5,0.1l0.3,0.1c1.8,0.4,3.4,1.2,4.9,2.3c0.3,0.2,0.6,0.3,1,0.3c0.5,0,1-0.2,1.3-0.6
		c0.2-0.3,0.3-0.7,0.3-1c0-0.5-0.2-1-0.6-1.3c-1.8-1.4-3.9-2.4-6.1-2.9c-0.2-0.1-0.4-0.1-0.7-0.2l-0.2,0c-0.1,0-0.3,0-0.4,0
		c-0.8,0-1.4,0.5-1.6,1.3C493.4,77.3,494,78.2,494.9,78.4z M442.5,65.9l1.4,0.3c1.8,0.4,3.6,0.9,5.4,1.3c0.1,0,0.3,0,0.4,0
		c0.8,0,1.4-0.5,1.6-1.3c0-0.1,0-0.2,0-0.4c0-0.8-0.5-1.4-1.3-1.6l-0.9-0.2c-2-0.5-4-0.9-5.9-1.4c-0.1,0-0.3,0-0.4,0
		c-0.8,0-1.4,0.5-1.6,1.3c-0.1,0.4,0,0.9,0.2,1.2C441.7,65.5,442.1,65.8,442.5,65.9z M425.1,61.7c1.2,0.3,2.4,0.6,3.6,0.9
		c1.1,0.3,2.2,0.5,3.3,0.8c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3c0-0.1,0-0.2,0-0.4c0-0.8-0.5-1.4-1.3-1.6c0,0,0,0,0,0
		c-1.5-0.4-2.9-0.7-4.4-1.1l-2.4-0.6c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3c-0.1,0.4,0,0.9,0.2,1.2
		C424.3,61.4,424.6,61.6,425.1,61.7z M375.6,62.3c0.2,0.1,0.4,0.1,0.6,0.1c0.7,0,1.3-0.4,1.5-1c0.8-1.9,2.1-3.6,3.6-4.9l0,0
		c0,0,0,0,0,0c0.4-0.3,0.6-0.8,0.6-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.4-0.8-0.6-1.3-0.6c-0.4,0-0.8,0.1-1,0.4
		c-2,1.7-3.6,3.8-4.6,6.2c-0.2,0.4-0.2,0.9,0,1.3C374.9,61.8,375.2,62.2,375.6,62.3z M407.6,57.6c1.7,0.4,3.4,0.8,5.1,1.2l1.7,0.4
		c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3c0-0.1,0-0.2,0-0.4c0-0.8-0.5-1.4-1.3-1.6c-2.4-0.6-4.6-1.1-6.8-1.6c-0.1,0-0.3,0-0.4,0
		c-0.8,0-1.4,0.5-1.6,1.3c-0.1,0.4,0,0.9,0.2,1.2C406.8,57.2,407.2,57.4,407.6,57.6z M492,155.6c-0.1,0-0.3,0-0.4,0
		c-0.8,0-1.4,0.5-1.6,1.3c-0.2,1-0.5,1.9-0.7,2.8c-0.3,1.3-0.6,2.7-1,4c0,0.1,0,0.2,0,0.4c0,0.8,0.5,1.4,1.3,1.6c0.1,0,0.3,0,0.4,0
		c0.8,0,1.4-0.5,1.6-1.3c0.3-1.4,0.7-2.9,1.1-4.4l0.6-2.5c0.1-0.4,0-0.9-0.2-1.3C492.8,156,492.4,155.7,492,155.6z M487.8,173.1
		c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3c-0.5,2.3-1.1,4.4-1.6,6.5l-0.1,0.3c0,0.1,0,0.2,0,0.4c0,0.8,0.5,1.4,1.3,1.6
		c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3l0.5-2c0.4-1.6,0.8-3.2,1.2-4.9c0.1-0.4,0-0.9-0.2-1.2
		C488.6,173.4,488.2,173.2,487.8,173.1z M496.2,138.2c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3c-0.6,2.3-1.1,4.6-1.7,6.8
		c0,0.1,0,0.2,0,0.4c0,0.8,0.5,1.4,1.3,1.6c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3c0.6-2.3,1.1-4.6,1.7-6.8
		C497.7,139.3,497.1,138.4,496.2,138.2z M483.6,190.5c-0.2-0.1-0.4-0.1-0.6-0.1c-0.7,0-1.3,0.4-1.6,1.1c-0.7,2-1.9,3.7-3.4,5.1
		c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.4,0.2,0.8,0.4,1.1c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.8-0.2,1.1-0.4c1.9-1.8,3.4-4,4.3-6.4
		C484.9,191.8,484.5,190.9,483.6,190.5z M508.7,88.6c-0.1-0.9-1-1.5-1.9-1.4c-0.4,0.1-0.8,0.3-1.1,0.7c-0.3,0.4-0.4,0.8-0.3,1.2
		c0.1,0.7,0.2,1.4,0.2,2.1c0,1-0.1,2-0.4,3c-0.1,0.4,0,0.9,0.2,1.2c0.2,0.4,0.6,0.6,1,0.7c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3
		c0.3-1.2,0.5-2.5,0.5-3.8C508.9,90.3,508.8,89.4,508.7,88.6z M500.5,120.8c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3
		c-0.5,2.2-1.1,4.5-1.7,6.8c0,0.1,0,0.2,0,0.4c0,0.8,0.5,1.4,1.3,1.6c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3
		c0.6-2.3,1.1-4.6,1.7-6.8C501.9,121.9,501.4,121,500.5,120.8z M484.3,75.9c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3
		c0-0.1,0-0.2,0-0.4c0-0.8-0.5-1.4-1.3-1.6c-1.1-0.3-2.2-0.5-3.3-0.8c-1.2-0.3-2.3-0.6-3.5-0.8c-0.1,0-0.3,0-0.4,0
		c-0.8,0-1.4,0.5-1.6,1.3c-0.1,0.4,0,0.9,0.2,1.2c0.2,0.4,0.6,0.6,1,0.7l1.1,0.3C480.5,75,482.4,75.5,484.3,75.9z M504.7,103.3
		c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3c-0.5,2.2-1.1,4.5-1.7,6.8c0,0.1,0,0.2,0,0.4c0,0.8,0.5,1.4,1.3,1.6c0.1,0,0.3,0,0.4,0
		c0.8,0,1.4-0.5,1.6-1.3c0.6-2.4,1.1-4.7,1.7-6.8c0.1-0.4,0-0.9-0.2-1.2C505.5,103.7,505.1,103.4,504.7,103.3z M452.1,196.2
		c-1.9-0.5-3.9-0.9-6-1.4l-0.9-0.2c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3c0,0.1,0,0.2,0,0.4c0,0.8,0.5,1.4,1.3,1.6h0
		c2.4,0.6,4.6,1.1,6.8,1.6c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3c0.1-0.4,0-0.9-0.2-1.3C452.9,196.6,452.6,196.3,452.1,196.2z
		 M434.7,192l-6.8-1.6c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3c0,0.1,0,0.2,0,0.4c0,0.8,0.5,1.4,1.3,1.6c0,0,0,0,0,0l6.8,1.6
		c0.1,0,0.3,0,0.4,0c0.8,0,1.4-0.5,1.6-1.3C436.1,193.2,435.6,192.3,434.7,192z M399.8,183.7c-2.2-0.5-4.4-1.1-6.6-1.6l-0.3-0.1
		c-0.9-0.2-1.8,0.3-2,1.2c0,0.1,0,0.2,0,0.4c0,0.8,0.5,1.4,1.3,1.6c2.2,0.5,4.5,1.1,6.8,1.6c0.1,0,0.3,0,0.4,0
		c0.8,0,1.4-0.5,1.6-1.3C401.2,184.8,400.7,183.9,399.8,183.7z M417.2,187.9c-2.3-0.5-4.6-1.1-6.8-1.6c-0.1,0-0.3,0-0.4,0
		c-0.8,0-1.4,0.5-1.6,1.3c0,0.1,0,0.2,0,0.4c0,0.8,0.5,1.4,1.3,1.6l1.6,0.4c1.7,0.4,3.5,0.8,5.2,1.2c0.1,0,0.3,0,0.4,0
		c0.8,0,1.4-0.5,1.6-1.3c0.1-0.4,0-0.9-0.2-1.2C418,188.2,417.7,188,417.2,187.9z M395.7,54.7L397,55c0.1,0,0.3,0,0.4,0
		c0.8,0,1.4-0.5,1.6-1.3c0-0.1,0-0.2,0-0.4c0-0.8-0.5-1.4-1.3-1.6l-1.6-0.4c-1-0.2-1.9-0.5-2.8-0.7c-0.9-0.2-1.8-0.4-2.7-0.4
		c-0.9-0.1-1.7,0.6-1.8,1.5c-0.1,0.9,0.6,1.7,1.5,1.8c0.7,0,1.5,0.2,2.2,0.3C393.6,54.2,394.6,54.4,395.7,54.7z M469.3,200
		c-1,0-1.9-0.1-2.8-0.4c-0.8-0.2-1.6-0.4-2.4-0.6l-1.3-0.3c-0.1,0-0.3,0-0.4,0c-0.8,0-1.4,0.5-1.6,1.3c0,0.1,0,0.2,0,0.4
		c0,0.8,0.5,1.4,1.3,1.6c1,0.2,2,0.5,2.9,0.7l0.8,0.2c1.2,0.3,2.4,0.4,3.6,0.4h0c0.9,0,1.6-0.7,1.6-1.6c0-0.4-0.2-0.9-0.5-1.2
		C470.1,200.2,469.7,200,469.3,200z M440.7,161c-5.1,1-10.9,0.7-17.6-1c-6.2-1.6-11.6-4.2-16.1-7.7c-4.5-3.5-7.6-7.8-9.1-13
		c-1.5-5.2-1.5-10.9,0.2-17.1c1.6-5.9,4.4-11.1,8.4-15.6c4.1-4.5,8.9-7.4,14.5-8.9c5.6-1.5,11.4-1.4,17.3,0.1
		c5.2,1.4,9.6,3.7,13.4,7.1c3.7,3.3,6.2,7.1,7.4,11.4c1.2,4.2,1.2,8.5,0.1,12.7c-1,3.6-2.7,6.9-5.3,9.9c-2.6,3-5.6,5.1-9,6.3
		c-2.6,1-4.5,1.3-5.9,0.9c-0.7-0.2-1.3-0.6-1.7-1.2c-0.4-0.6-0.5-1.3-0.3-2c0.1-0.4,1-2.2,2.7-5.6l12.2-24.1l-6.8-1.8l-2.8,5.5
		c-0.8-2.7-2.1-4.9-3.8-6.6c-1.7-1.7-3.6-2.8-5.9-3.4c-2.9-0.8-6.1-0.5-9.7,0.7c-3.6,1.2-7,3.6-10.1,7.1c-3.1,3.5-5.2,7.3-6.3,11.3
		c-0.9,3.3-1,6.4-0.4,9.5c0.6,3.1,1.8,5.6,3.6,7.4c1.8,1.9,3.8,3.1,6.1,3.7c2.1,0.5,4.2,0.5,6.6-0.1c2.3-0.6,4.6-1.7,6.7-3.1
		c-0.3,1.6-0.3,2.7-0.1,3.5c0.2,1.1,0.8,2.1,1.8,2.9c0.9,0.8,2.3,1.5,4,1.9c5.5,1.4,11.2,0.2,17.1-3.7c6.7-4.3,11-10.2,12.9-17.5
		c1.4-5.1,1.4-10.2,0.1-15.3c-1.5-5.9-4.5-10.7-8.8-14.6c-4.3-3.9-9.6-6.7-15.9-8.3c-8.1-2.1-15.5-2.1-22.1,0
		c-6.6,2.1-12.4,6.3-17.3,12.3c-4,4.9-6.8,10.5-8.5,16.7c-1.5,5.5-1.8,11-0.9,16.3c0.7,4.2,2.3,8.2,4.9,12c2.6,3.8,6.1,7,10.4,9.7
		c4.4,2.7,9.4,4.8,15,6.3c6.1,1.6,11.5,2.2,16.3,1.7c4.8-0.5,9.1-1.7,12.9-3.6c3.9-1.9,6.8-4.1,8.8-6.4l-6.9-1.8
		C449.8,158.1,445.8,159.9,440.7,161z M434.2,133.2c-1.6,2.3-3.3,4.1-5.2,5.4c-1.9,1.3-3.7,2.2-5.5,2.6c-1.8,0.4-3.4,0.5-4.8,0.1
		c-2.1-0.6-3.8-2-4.9-4.4c-1.1-2.4-1.3-5.3-0.3-8.7c0.6-2.2,1.6-4.5,3.1-6.9c1.5-2.4,3.2-4.3,5-5.8c1.8-1.5,3.6-2.4,5.2-2.9
		c1.6-0.4,3.3-0.5,4.9,0c2.5,0.6,4.3,2.1,5.5,4.5c1.2,2.4,1.4,5.2,0.5,8.7C436.9,128.5,435.8,130.9,434.2,133.2z"
        />
      </g>
      <g className="image-card">
        <path
          fill="#ffffff"
          d="M342.5,142.4c-1-3.7-3.4-6.9-6.8-8.8c-3.3-1.9-7.3-2.4-11-1.4c-37,10.1-137.1,37.3-174,47.3
			c-7.7,2.1-12.3,10-10.2,17.8c9.5,35.2,34.2,127.5,43.7,162.8c1,3.7,3.4,6.9,6.8,8.8c3.3,1.9,7.3,2.4,11,1.4
			c37-10.1,137.1-37.3,174-47.3c7.7-2.1,12.3-10,10.2-17.8C376.8,269.9,352,177.7,342.5,142.4z"
        />
        <path
          fill="#006DF3"
          d="M363.7,290.5c-7.8-28.9-25.5-95-33.3-124c-1.2-4.3-4.1-8.1-8-10.4c-3.9-2.2-8.6-2.8-13-1.7l-28.4,7.7
		C246,171.7,198.3,184.7,175,191c-9,2.5-14.4,11.8-12,20.9l33.3,124c1.2,4.3,4.1,8.1,8,10.4c2.6,1.5,5.5,2.3,8.5,2.3h0
		c1.5,0,3-0.2,4.5-0.6l134.4-36.5C360.7,308.9,366.1,299.6,363.7,290.5z M167.9,210.6c-1.7-6.4,2.1-12.9,8.4-14.7
		c23.3-6.3,71-19.3,106.1-28.8l28.4-7.7c1-0.3,2.1-0.4,3.1-0.4c2.1,0,4.2,0.6,6,1.6c2.7,1.6,4.8,4.2,5.6,7.3
		c2.3,8.6,5.5,20.6,9.1,33.8l-52.3,62.7L217,243.7c-1.1-0.3-2.2,0-2.8,0.9l-27,37.6L167.9,210.6z M350.4,306.5L215.9,343
		c-1,0.3-2.1,0.4-3.1,0.4h0c-2.1,0-4.2-0.5-6-1.6c-2.7-1.6-4.8-4.2-5.6-7.3l-12.4-46l28.3-39.5l65.1,20.6c1,0.3,2.1,0,2.7-0.8
		l51.1-61.3c8.1,30.1,17.5,65.2,22.6,84.2C360.5,298.2,356.7,304.8,350.4,306.5z M279.5,219.2c2.5,0,5-0.5,7.3-1.5
		c9.4-4.2,13.7-15.6,9.6-25.4c-3.1-7.2-9.9-11.9-17.5-11.9c-2.5,0-5,0.5-7.3,1.5c-9.4,4.2-13.7,15.6-9.6,25.4
		C265.1,214.6,271.9,219.2,279.5,219.2z M273.6,186.5c1.7-0.7,3.4-1.1,5.2-1.1c5.5,0,10.6,3.5,12.8,8.8c3.1,7.3,0,15.8-7,18.8
		c-1.7,0.7-3.4,1.1-5.2,1.1c-5.5,0-10.6-3.5-12.8-8.8C263.6,198,266.7,189.6,273.6,186.5z"
        />
      </g>
      <g className="heart-card">
        <path
          fill="#ffffff"
          d="M659.6,153.6c-0.7-3.8-2.9-7.2-6.1-9.3c-3.2-2.2-7.1-3-10.9-2.3c-19,3.6-52.3,10-71.3,13.7
			c-7.8,1.5-13,9.1-11.5,16.9c3.4,18.6,9.4,50.9,12.9,69.5c0.7,3.8,2.9,7.2,6.1,9.3c3.2,2.2,7.1,3,10.9,2.3
			c19-3.7,52.4-10.1,71.3-13.7c7.8-1.5,13-9,11.5-16.9C669.1,204.6,663.1,172.2,659.6,153.6z"
        />
        <path
          fill="#006DF3"
          d="M663.4,214.6c-2.6-14.1-6.6-35.5-9.2-49.6c-0.8-4.4-3.4-8.4-7.1-11c-2.8-1.9-6.2-3-9.6-3
		c-1.1,0-2.2,0.1-3.2,0.3c-14.6,2.8-37.2,7.1-51.7,9.9c-9.2,1.8-15.2,10.7-13.5,19.8l9.2,49.6c0.8,4.4,3.4,8.4,7.1,11
		c2.8,1.9,6.2,3,9.6,3c1.1,0,2.2-0.1,3.2-0.3c14.6-2.8,37.2-7.1,51.7-9.9C659.1,232.6,665.1,223.7,663.4,214.6z M648.9,229.4
		c-14.6,2.8-37.1,7.1-51.7,9.9c-0.7,0.1-1.5,0.2-2.3,0.2c-2.4,0-4.7-0.7-6.7-2.1c-2.6-1.8-4.4-4.6-5-7.7l-9.2-49.6
		c-1.2-6.4,3.1-12.7,9.5-13.9c14.6-2.8,37.1-7.1,51.7-9.9c0.7-0.1,1.5-0.2,2.3-0.2c2.4,0,4.8,0.7,6.7,2.1c2.6,1.8,4.4,4.6,5,7.7
		c2.6,14.1,6.6,35.5,9.2,49.6C659.6,221.9,655.4,228.2,648.9,229.4z M630.7,179.6c-1.9-1.2-4.1-1.8-6.4-1.8c-4.4,0-8.4,2.1-10.9,5.6
		c-2.1-1.4-4.6-2.1-7.2-2.1c-4.1,0-8.1,1.9-10.3,5c-4,5.4-2.6,13.7,3.5,21.3c4.7,6,14.3,10.3,19.6,12.3c0.3,0.1,0.6,0.2,0.9,0.2
		c0.6,0,1.2-0.2,1.7-0.7c4.2-3.9,11.6-11.4,13.7-18.7C638.2,191.4,636.4,183.2,630.7,179.6z M630.5,199.2
		C630.5,199.2,630.5,199.3,630.5,199.2c-1.2,4.3-5.2,9.8-11.1,15.4c-7.5-3-13.3-6.7-16-10.1c0,0,0,0,0-0.1
		c-4.5-5.5-5.9-11.7-3.4-15.1c1.3-1.8,3.8-2.9,6.2-2.9c1.6,0,4,0.5,6.1,2.7c0.6,0.6,1.5,0.9,2.3,0.8c0.9-0.2,1.6-0.8,1.9-1.6
		c1.6-4,5-5.4,7.8-5.4c1.4,0,2.7,0.4,3.7,1C631.6,186.1,632.6,192.4,630.5,199.2z"
        />
      </g>
      <g className="message-paper">
        <path
          fill="#E6F1FF"
          d="M620.4,342.9c1.2-5.2-2-10.4-7.1-11.6c-49-11.7-275.9-65.8-324.9-77.5c-2.5-0.6-5.1-0.2-7.3,1.2
			c-2.2,1.3-3.7,3.5-4.3,6c-10.6,44.5-55.9,235.1-68.4,287.5c-0.7,3,0.1,6.2,2.1,8.6c2.1,2.3,5.1,3.5,8.2,3.2
			c126.4-13.2,225.2,27.5,319.1,77.7c2.6,1.4,5.8,1.5,8.5,0.3c2.7-1.2,4.7-3.6,5.4-6.5C563.7,581,609.7,387.7,620.4,342.9z"
        />
        <g fill="#86BCFF">
          <rect
            x="375.2"
            y="384.3"
            transform="matrix(0.2317 -0.9728 0.9728 0.2317 -182.6021 745.813)"
            width="11.4"
            height="208.4"
          />
          <rect
            x="403.8"
            y="334.1"
            transform="matrix(0.2317 -0.9728 0.9728 0.2317 -133.5664 752.264)"
            width="11.4"
            height="253.3"
          />
          <rect
            x="410.5"
            y="303.5"
            transform="matrix(0.2318 -0.9728 0.9728 0.2318 -98.641 735.2729)"
            width="11.4"
            height="253.3"
          />
          <rect
            x="417.1"
            y="271.6"
            transform="matrix(0.2317 -0.9728 0.9728 0.2317 -62.6239 717.2366)"
            width="11.4"
            height="253.3"
          />
          <rect
            x="378.6"
            y="252.1"
            transform="matrix(0.2318 -0.9728 0.9728 0.2318 -18.2018 617.908)"
            width="6.9"
            height="136.7"
          />
        </g>
      </g>
      <g className="envelope">
        <path
          fill="#77B0E8"
          d="M406.5,554.7L152.7,386c-5.2,62.3-21.4,254.2-26.7,316.6c-0.9,11,7.2,20.6,18.1,21.5
	c81,7,386.5,33.4,467.6,40.4c5.3,0.5,10.5-1.2,14.6-4.6c4-3.4,6.6-8.3,7-13.6c4.9-57.8,19.1-226.2,25.3-300.4L406.5,554.7z"
        />
        <path
          fill="#3187DD"
          d="M127.4,711.6c2.7,6.8,9,11.9,16.8,12.5c81,7,386.5,33.4,467.6,40.4c5.3,0.5,10.5-1.2,14.6-4.6
	c4-3.4,6.6-8.3,7-13.6c0.2-2.7,0.5-5.6,0.7-8.7L406.5,554.6l0,0l0,0L127.4,711.6z"
        />
      </g>
    </svg>
  )
}

export default ContactAnimation
